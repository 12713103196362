import React from "react";
import { Button, Layout, Menu } from "antd";
import {
  LogoutOutlined,
  UsergroupAddOutlined,
  FileOutlined,
  SettingOutlined,
  UnorderedListOutlined,
  DashboardOutlined,
  LaptopOutlined,
  OrderedListOutlined,
} from "@ant-design/icons";
import { useLocation, useHistory } from "react-router-dom";
import moment from "moment";

const GuestLayout = ({ children }) => {
  const { Content, Footer, Sider } = Layout;
  const location = useLocation();
  const history = useHistory();

  const handleLogout = () => {
    localStorage.removeItem(process.env.REACT_APP_LS_TOKEN);
    history.push("/");
  };

  const getSelectedKey = () => {
    switch (location.pathname) {
      case "/dashboard":
        return "1";
      case "/agents":
        return "2";
      case "/limits":
        return "3";
      case "/results":
        return "4";
      case "/bets":
        return "6";
      case "/all-transactions":
        return "7";
      case "/operation-stats":
        return "8";
      case "/topup-dashboard":
        return "9";
      case "/user-info":
        return "10";
      default:
        break;
    }
  };

  return (
    <div className="flex flex-col h-auto">
      <Layout style={{ minHeight: "100vh" }}>
        <Sider defaultCollapsed={true} theme="light" collapsible width="250">
          <Menu theme="light" selectedKeys={getSelectedKey()} mode="inline">
            <Menu.Item
              key="1"
              icon={<LaptopOutlined />}
              onClick={() => history.push("/dashboard")}
              style={{ fontSize: 16 }}
            >
              Dashboard
            </Menu.Item>
            <Menu.Item
              key="9"
              icon={<LaptopOutlined />}
              onClick={() => history.push("/topup-dashboard")}
              style={{ fontSize: 16 }}
            >
              Dashboard
            </Menu.Item>
            <Menu.Item
              key="2"
              icon={<UsergroupAddOutlined />}
              onClick={() => history.push("/agents")}
              style={{ fontSize: 16 }}
            >
              Agents
            </Menu.Item>
            {/* <Menu.Item
              key="3"
              icon={<DashboardOutlined />}
              onClick={() => history.push("/limits")}
              style={{ fontSize: 16 }}
            >
              Limits
            </Menu.Item> */}
            <Menu.Item
              key="4"
              icon={<UnorderedListOutlined />}
              onClick={() => history.push("/results")}
              style={{ fontSize: 16 }}
            >
              Results
            </Menu.Item>
            <Menu.Item
              key="6"
              icon={<OrderedListOutlined />}
              onClick={() => history.push("/bets")}
              style={{ fontSize: 16 }}
            >
              Bets
            </Menu.Item>
            <Menu.Item
              key="7"
              icon={<FileOutlined />}
              onClick={() => history.push("/reports")}
              style={{ fontSize: 16 }}
            >
              Reports
            </Menu.Item>
            <Menu.Item
              key="8"
              icon={<FileOutlined />}
              onClick={() => history.push("/operation-stats")}
              style={{ fontSize: 16 }}
            >
              Operation stats
            </Menu.Item>
            {/* <Menu.Item
              key="9"
              icon={<SettingOutlined />}
              onClick={() => history.push("/server-configuration")}
              style={{ fontSize: 16 }}
            >
              Server Configuration
            </Menu.Item> */}
            <Menu.Item
              style={{ fontSize: 16 }}
              key="5"
              icon={<LogoutOutlined />}
              onClick={handleLogout}
            >
              Logout
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <div className="h-12 flex flex-row justify-between mb-6 p-3 w-full bg-white">
            <p className="text-gray-500">
              Logged in: {moment().format("MMMM, DD YYYY hh:ss a")}
            </p>
            <div className="flex flex-row">
              <p className="mr-3">Coordinator</p>
              <Button
                type="text"
                shape="circle"
                size="small"
                onClick={() => history.push("/user-info")}
              >
                <img
                  alt="avatar"
                  src={require("../assets/avatar.png")}
                  style={{ height: 30, width: 30 }}
                />
              </Button>
            </div>
          </div>
          <Content
            style={{ margin: "0 16px", borderRadius: 25 }}
            className="bg-white"
          >
            {children}
          </Content>
          <Footer style={{ textAlign: "center" }}>Admin @2021</Footer>
        </Layout>
      </Layout>
    </div>
  );
};

export default GuestLayout;
