import { Spin, Table } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useContext } from "react";
import { PageContext } from "../../../lib/context";
import { ExportCsvData } from "../exportCsvData";
import moment from "moment";
import { formatMoney } from "../../../lib/util";
import { useQuery } from "@apollo/react-hooks";
import { GET_AGENT_BETS, MULTIPLIER } from "../../../lib/query";

export const BetWinners = () => {
  const { bet_winners, loading_bet_winners,coordinatorId } = useContext(PageContext);
  const { data: daily_bets } = useQuery(GET_AGENT_BETS, {
    variables: {},
  });

  const { data: multiplier } = useQuery(MULTIPLIER)

  const [winners, setWinners] = useState([]);

  const bet_winners_columns = [
    {
      title: "Agent",
      dataIndex: "agent",
      key: "agent",
    },
    {
      title: "Agent Type",
      dataIndex: "agent_type",
      key: "agent_type",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Number",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      sorter: {
        compare: (a, b) => {
          return a.amount - b.amount;
        },
        multiple: 3,
      },
    },
    {
      title: "Payable",
      dataIndex: "payable",
      key: "payable",
      sorter: {
        compare: (a, b) => {
          return a.payable - b.payable;
        },
        multiple: 3,
      },
    },
  ];

  useMemo(() => {
    let newbet = 0;
    let newpay = 0;
    if (bet_winners) {
      let rumbleMultiplier;
      let straightMultiplier;
      let counter = 0;
      const data = [];
      bet_winners.results.map((result) => {
        result.bets.map((bet) => {
          const combi = bet.number.split("");
                    console.log("bet.number",bet.number)
          console.log("bet.drawNumber",bet.drawNumber)
          console.log("bet_winners.results?.[0].combination",bet_winners.results?.[0].combination)
          console.log("bet_winners.results?.[0].drawNumber",bet_winners.results?.[0].drawNumber)
          if (
            bet.number === bet_winners.results?.[counter].combination &&
            bet.drawNumber === bet_winners.results?.[counter].drawNumber
          ) {
          console.log("hey betamount", bet.user.agent_type);
          if (bet.user.agent_type === "outlet") {
            rumbleMultiplier = parseFloat(
              multiplier?.serverConfigs.filter(
                (item) => item.type === "outlet_winning_multiplier"
              )[0].value
            );
            straightMultiplier = parseFloat(
              multiplier?.serverConfigs.filter(
                (item) => item.type === "outlet_winning_multiplier_same_digit"
              )[0].value
            );
          } else {
            rumbleMultiplier = parseFloat(
              multiplier?.serverConfigs.filter(
                (item) => item.type === "winning_multiplier"
              )[0].value
            );
            straightMultiplier = parseFloat(
              multiplier?.serverConfigs.filter(
                (item) => item.type === "winning_multiplier_same_digit"
              )[0].value
            );
          }
          console.log("config",multiplier)
          if (bet.type == "Rumble") {
            if (
              combi[0] === combi[1] ||
              combi[0] === combi[2] ||
              combi[1] === combi[2]
            ) {
              newpay = bet.amount * rumbleMultiplier;
            } else {
              newpay = bet.amount * rumbleMultiplier;
            }
          } else {
            newbet = bet.amount;
            if (combi[0] === combi[1] && combi[0] === combi[2]) {
              newpay = newbet * straightMultiplier;
            } else {
              newpay = newbet * rumbleMultiplier;
            }
          }
          console.log(newbet);
          const item = {
            agent: bet.user.username,
            agent_type: bet.user.agent_type,
            date: moment(bet.created_at).format("MMMM DD,YYYY HH:mm:ss"),
            number: bet.number,
            type: bet.type,
            amount: bet.amount.toFixed(2),
            payable: newpay.toFixed(2),
          };
          console.log(bet.amount);
          data.push(item);
        }
        });
        counter++;
      });
      setWinners(data);
    }
  }, [bet_winners, multiplier]);

  return (
    <div className="mt-10 flex flex-row">
      <div className="w-screen mr-4">
        <ExportCsvData
          title="betWinners"
          filename="BetWinners.csv"
          betWinners={winners}
        />
        <p className="text-xs font-bold mb-1">Bet Winners</p>
        {loading_bet_winners ? (
          <div className="flex h-full">
            <Spin className="self-center" />
          </div>
        ) : (
          <Table columns={bet_winners_columns} dataSource={winners} />
        )}
      </div>
    </div>
  );
};
