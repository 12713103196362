import React from "react";
import { useContext } from "react";
import { PageContext } from "../../lib/context";
import { Button, Popconfirm, Spin, Table } from "antd";
import { bets_columns, individual_columns } from "./tables";
import moment from "moment";
import { DeleteOutlined } from "@ant-design/icons";
import { AddResult } from "./components/addResult";

const ResultsView = () => {
  const { setVisible, visible, results, loading_results, confirm } = useContext(
    PageContext
  );

  const results_columns = [
    {
      title: "Combination",
      dataIndex: "combination",
      key: "combination",
    },
    {
      title: "Draw",
      dataIndex: "drawNumber",
      key: "drawNumber",
    },
    {
      title: "Date",
      render: (item) => {
        return <p>{moment(item.date).format("MMMM DD, YYYY")}</p>;
      },
    },
    // {
    //   title: "Actions",
    //   render: (item) => {
    //     return (
    //       <Popconfirm
    //         title={`Delete result ${item.combination}?`}
    //         onConfirm={() => confirm({ ...item })}
    //         cancelButtonProps={{ style: { display: "none" } }}
    //         okText="Yes"
    //         cancelText="No"
    //       >
    //         <DeleteOutlined className="cursor-pointer" />
    //       </Popconfirm>
    //     );
    //   },
    // },
  ];

  return (
    <div className="flex flex-col h-auto pt-20 px-20">
      <p className="text-3xl font-bold secondary-color">Results</p>
      <div className="mt-10 flex flex-row">
        <div className="w-screen mr-4">
          {/* <Button
            className="w-32 mb-5"
            type="primary"
            onClick={() => {
              setVisible({ ...visible, limit: true });
            }}
          >
            Add result
          </Button> */}
          <p className="text-xs font-bold">Results</p>
          {loading_results ? (
            <div className="flex h-full">
              <Spin className="self-center" />
            </div>
          ) : (
            <Table
              dataSource={results?.results ?? []}
              columns={results_columns}
            />
          )}
        </div>
      </div>

      <AddResult />
    </div>
  );
};

export default ResultsView;
