import { DatePicker, Table, Tabs } from "antd";
import React, { useContext } from "react";
import { PageContext } from "../../lib/context";

const { TabPane } = Tabs;

const OperationStatsView = () => {
  const { handleDateChange, dailyData, draw1, draw2, draw3 } = useContext(
    PageContext
  );

  const columns = [
    {
      dataIndex: "number",
      key: "number",
      title: "Number",
    },
    {
      dataIndex: "amount",
      key: "amount",
      title: "Amount",
      sorter: {
        compare: (a, b) => {
          return a.amount - b.amount;
        },
        multiple: 3,
      },
    },
    {
      dataIndex: "betCount",
      key: "betCount",
      title: "Bet Count",
      sorter: {
        compare: (a, b) => {
          return a.betCount - b.betCount;
        },
        multiple: 3,
      },
    },
    {
      dataIndex: "percentage",
      key: "percentage",
      title: "Percentage",
      sorter: {
        compare: (a, b) => {
          return a.percentage - b.percentage;
        },
        multiple: 3,
      },
    },
  ];

  return (
    <div className="flex flex-col h-auto pt-20 px-20">
      <p className="text-3xl font-bold secondary-color">Operation Stats</p>
      <DatePicker
        placeholder="Date"
        className="mr-5 mb-5 w-32"
        onChange={(date) => handleDateChange(date)}
      />
      <Tabs defaultActiveKey="1" size="large" type="card">
        <TabPane tab="Daily" key="1">
          <p className="font-bold">Total Amount: {dailyData.totalBetsAmount}</p>
          <p className="font-bold">
            Total Bets Count: {dailyData.totalBetsCount}
          </p>
          <Table dataSource={dailyData.tableData} columns={columns} />
        </TabPane>
        <TabPane tab="Draw 1" key="draw1">
          <p className="font-bold">Total Amount: {draw1.totalBetsAmount}</p>
          <p className="font-bold">Total Bets Count: {draw1.totalBetsCount}</p>
          <Table dataSource={draw1.tableData} columns={columns} />
        </TabPane>
        <TabPane tab="Draw 2" key="draw2">
          <p className="font-bold">Total Amount: {draw2.totalBetsAmount}</p>
          <p className="font-bold">Total Bets Count: {draw2.totalBetsCount}</p>
          <Table dataSource={draw2.tableData} columns={columns} />
        </TabPane>
        <TabPane tab="Draw 3" key="draw3">
          <p className="font-bold">Total Amount: {draw3.totalBetsAmount}</p>
          <p className="font-bold">Total Bets Count: {draw3.totalBetsCount}</p>
          <Table dataSource={draw3.tableData} columns={columns} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default OperationStatsView;
