import React, { useEffect, useState } from "react";
import DashboardView from "./view";
import { PageContext } from "../../lib/context";
import { useMutation, useQuery, useSubscription } from "@apollo/react-hooks";
import _ from "lodash";
import {
  AGENTS,
  GET_ACTIVE_AGENTS,
  GET_AGENT_TODAY,
  GET_BETS,
  GET_RESULTS,
} from "../../lib/subscriptions";
import { UPDATE_USER_STATUS } from "../../lib/mutations";
import jwt_decode from "jwt-decode";
import moment from "moment";

const AgentsController = () => {
  const [visible, setVisible] = useState({
    agent: false,
    bulkagent: false,
  });
  const [Bulkvalue, setBulkValue] = useState(0);
  const [resetPassModal, setResetPassModal] = useState(false);
  const [row, setRow] = useState({});
  const [id, setId] = useState();
  const [coordinatorId, setCoordinatorId] = useState();
  const [showForm, setShowForm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [date, setDate] = useState({
    start: moment().startOf('day').format('YYYY-MM-DD'),
    end: moment().add(1,'days').format('YYYY-MM-DD')

  });
  const [agentId, setAgentId] = useState();
  const [where1, setWhere1] = useState();
  const [where2, setWhere2] = useState();
  const [where3, setWhere3] = useState();
  const [percentage,setPercentage] = useState();

  const { data: results } = useQuery(GET_RESULTS, {
    variables: {
      start: date?.start,
      end: date?.end
    },
  });

  const { data: bets, loading:loading_bets } = useQuery(GET_BETS, {
    variables: {
      where1: where1,
      where2: where2,
      where3: where3,
    },
  });

  const { data: agents, loading: loading_agents } = useSubscription(AGENTS, {
    variables: {
      coordinatorId: coordinatorId,
    },
  });
  const { data: active_agents } = useSubscription(GET_ACTIVE_AGENTS, {
    variables: {
      coordinatorId: coordinatorId,
    },
  });

  const [updateStatus] = useMutation(UPDATE_USER_STATUS);

  const onRowClick = (item) => {
    setRow(item);
    setShowForm(true);
  };

  const showPercentageModal = (item) => {
    console.log("bets",bets)
    setPercentage(item?.percentage)
    setAgentId(item?.id);
    setShowModal(true);
  };

  const showPassModal = () => {
    setResetPassModal(true);
    setVisible({ ...visible, agent: false });
  };

  const closePassModal = () => {
    setResetPassModal(false);
    setVisible({ ...visible, agent: true });
  };

  const handleSetStatus = async (item) => {
    await updateStatus({
      variables: {
        id: item.id,
        status: !item.isActivated,
      },
    });
  };

  const dateChange = (date) => {
    setDate({
      start: moment(date).startOf('day').format('YYYY-MM-DD'),
      end: moment(date).add(1,'days').format('YYYY-MM-DD')
    })
  };

  useEffect(() => {
    results?.result_draw1?.[0]?.combination
      ? setWhere1({
          agentId: { _eq: agentId },
          _and: [{created_at: {_gte: date?.start}}, {created_at: {_lte: date?.end}} ],
          drawNumber: { _eq: 1 },
          number: { _neq: results?.result_draw1?.[0]?.combination },
        })
      : setWhere1({
          agentId: { _eq: agentId },
          _and: [{created_at: {_gte: date?.start}}, {created_at: {_lte: date?.end}} ],
          drawNumber: { _eq: 1 },
        });

    results?.result_draw2?.[0]?.combination
      ? setWhere2({
          agentId: { _eq: agentId },
          _and: [{created_at: {_gte: date?.start}}, {created_at: {_lte: date?.end}} ],
          drawNumber: { _eq: 2 },
          number: { _neq: results?.result_draw2?.[0]?.combination },
        })
      : setWhere2({
          agentId: { _eq: agentId },
          _and: [{created_at: {_gte: date?.start}}, {created_at: {_lte: date?.end}} ],
          drawNumber: { _eq: 2 },
        });

    results?.result_draw3?.[0]?.combination
      ? setWhere3({
          agentId: { _eq: agentId },
          _and: [{created_at: {_gte: date?.start}}, {created_at: {_lte: date?.end}} ],
          drawNumber: { _eq: 3 },
          number: { _neq: results?.result_draw3?.[0]?.combination },
        })
      : setWhere3({
          agentId: { _eq: agentId },
          _and: [{created_at: {_gte: date?.start}}, {created_at: {_lte: date?.end}} ],
          drawNumber: { _eq: 3 },
        });
  }, [results,agentId]);

  useEffect(() => {
    console.log(agentId);
    let token = localStorage.getItem(process.env.REACT_APP_LS_TOKEN);
    var decode = jwt_decode(token);
    setCoordinatorId(decode.sub);
  },[agentId]);

  const pageValues = {
    agents,
    coordinatorId,
    loading_agents,
    visible,
    row,
    resetPassModal,
    id,
    showForm,
    setVisible,
    onRowClick,
    setRow,
    setBulkValue,
    Bulkvalue,
    closePassModal,
    showPassModal,
    setId,
    setShowForm,
    handleSetStatus,
    active_agents,
    showModal,
    setShowModal,
    showPercentageModal,
    percentage,
    bets,
    dateChange,
    date,
    agentId
  };

  return (
    <PageContext.Provider value={pageValues}>
      <DashboardView />
    </PageContext.Provider>
  );
};

export default AgentsController;
