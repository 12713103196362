import gql from "graphql-tag";

export const BETS = gql`
  subscription MyQuery($betsWhere: bets_bool_exp) {
    bets(where: $betsWhere, limit: 100, order_by: { created_at: desc }) {
      transactionId
      agentId
      amount
      created_at
      drawNumber
      id
      number
      updated_at
      type
      user {
        id
        username
      }
    }
  }
`;

export const TOPUPS = gql`
subscription MyQuery($coordinatorId: uuid) {
  coordinatorTransactions(limit: 100, order_by: {created_at: desc}, where: {coordinatorId: {_eq: $coordinatorId}}) {
    id
    amount
    user {
      id
      username
    }
    top_up_agent_username
    coordinatorId
  }
}
`;

export const AGENTS = gql`
subscription MyQuery($coordinatorId: uuid) {
  users(where: {type: {_eq: "agent"}, coordinatorId: {_eq: $coordinatorId}}, order_by: [{created_at: desc},{username: desc}]) {
    percentage
    created_at
    email
    firstname
    id
    isActivated
    lastname
    municipality
    type
    updated_at
    username
    agent_type
  }
}
`;

export const USERS = gql`
  subscription MyQuery {
    users(where: { type: { _eq: "admin" } }, order_by: { created_at: desc }) {
      created_at
      email
      firstname
      id
      isActivated
      lastname
      municipality
      type
      updated_at
      username
    }
  }
`;

export const INDIVIDUAL_LIMITS = gql`
  subscription MySubscription {
    betsLimits(where: { type: { _eq: "individual" } }) {
      created_at
      date
      draw
      id
      limit
      number
      type
      updated_at
      currentLimit
    }
  }
`;

export const GLOBAL_LIMITS = gql`
  subscription MySubscription {
    betsLimits(where: { type: { _eq: "global" } }, order_by: { number: asc }) {
      created_at
      date
      draw
      id
      limit
      number
      type
      updated_at
      currentLimit
    }
  }
`;

export const RESULTS = gql`
  subscription MySubscription {
    results(order_by: { date: desc }) {
      combination
      created_at
      date
      drawNumber
      id
      updated_at
    }
  }
`;

export const SERVER_CONFIGS = gql`
  subscription MySubscription {
    serverConfigs {
      created_at
      id
      type
      updated_at
      value
    }
  }
`;

export const GET_BULK_AGENTS_COUNT = gql`
  subscription MySubscription {
    users_aggregate(where: { username: { _like: "AGT%" } }) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_BULK_AGENTS_NUMBER = gql`
  subscription MySubscription2 {
    users(where: { username: { _like: "4%" } }, order_by: { username: desc }) {
      username
    }
  }
`;
export const GET_BULK_AGENTS = gql`
  subscription MySubscription {
    users_aggregate(where: { username: { _like: "AGT%" } }) {
      aggregate {
        count
      }
    }
  }
`;

export const BET_WINNERS = gql`
query MyQuery($resultsWhere: results_bool_exp, $betsWhere: bets_bool_exp) {
    results(where: $resultsWhere, order_by: { created_at: desc }) {
      combination
      created_at
      date
      drawNumber
      id
      updated_at
      bets(where: $betsWhere) {
        amount
        agentId
        created_at
        drawNumber
        id
        number
        type
        updated_at
        user {
          username
          agent_type
        }
      }
    }
  }
`;

export const BETS_LIST = gql`
subscription MySubscription($date: timestamptz) {
    bets(
      where: { created_at: { _gte: $date } }
      order_by: { created_at: desc }
    ) {
      drawNumber
      number
      amount
    }
    bets_aggregate(where: {created_at: {_gte: $date}}) {
      aggregate {
        count
        sum {
          amount
        }
      }
    }
  }
`;

export const GET_DAILY_BETS = gql`
  subscription MySubscription($date: timestamptz) {
  bets_aggregate(where: {created_at: {_gte: $date}}) {
    aggregate {
      sum {
        amount
      }
      count
    }
  }
}
`;

export const GET_WEEKLY_BETS = gql`
subscription MySubscription($date: timestamptz) {
    bets_aggregate(where: { created_at: { _gte: $date } }) {
      aggregate {
        sum {
          amount
        }
      }
    }
  }
`;

export const GET_MONTHLY_BETS = gql`
subscription MySubscription($date: timestamptz) {
    bets_aggregate(where: { created_at: { _gte: $date } }) {
      aggregate {
        sum {
          amount
        }
      }
    }
  }
`;

export const GET_DAILY_TOP_UPS = gql`
  subscription MySubscription($date: timestamptz,$coordinatorId:uuid) {
  coordinatorTransactions_aggregate(where: {created_at: {_gte: $date}, coordinatorId: {_eq: $coordinatorId}}) {
    aggregate {
      sum {
        amount
      }
      count
    }
  }
}
`;

export const GET_WEEKLY_TOP_UPS = gql`
subscription MySubscription($date: timestamptz,$coordinatorId:uuid) {
    coordinatorTransactions_aggregate(where: { created_at: { _gte: $date },coordinatorId: {_eq: $coordinatorId} }) {
      aggregate {
        sum {
          amount
        }
      }
    }
  }
`;

export const GET_MONTHLY_TOP_UPS = gql`
subscription MySubscription($date: timestamptz,$coordinatorId:uuid) {
    coordinatorTransactions_aggregate(where: { created_at: { _gte: $date },coordinatorId: {_eq: $coordinatorId} }) {
      aggregate {
        sum {
          amount
        }
      }
    }
  }
`;

export const GET_ACTIVE_AGENTS = gql`
subscription MySubscription($coordinatorId: uuid) {
  users_aggregate(where: {isActivated: {_eq: true}, coordinatorId: {_eq: $coordinatorId}}) {
    aggregate {
      count
    }
  }
}
`;

export const REPORTS_BETS = gql`
query MyQuery($betsWhere: bets_bool_exp, $order_by: [bets_order_by!]) {
    bets: bets(where: $betsWhere, order_by: $order_by) {
      agentId
      amount
      created_at
      drawNumber
      id
      number
      type
      updated_at
      user {
        username
        agent_type
      }
    }
  }
`;

export const GET_AGENT_TODAY = gql`
subscription MySubscription($date: timestamptz) {
  bets_aggregate(distinct_on: agentId, where: {created_at: {_gte: $date}}) {
    aggregate {
      count
    }
  }
}
`;


export const GET_RESULTS = gql`
query MyQuery($start:timestamptz,$end:timestamptz){
  result_draw1:results(where:{ drawNumber: {_eq: 1},_and: [{date: {_gte: $start}}, {date: {_lte: $end}} ]}) {
    combination
  }
  result_draw2:results(where: { drawNumber: {_eq: 2},_and: [{date: {_gte: $start}}, {date: {_lte: $end}} ]}) {
    combination
  }
  result_draw3:results(where: { drawNumber: {_eq: 3},_and: [{date: {_gte: $start}}, {date: {_lte: $end}} ]}) {
    combination
  }
}
`

export const GET_BETS = gql`
query MyQuery($where1: bets_bool_exp,$where2: bets_bool_exp, $where3: bets_bool_exp) {
  bets_draw1: bets_aggregate(where: $where1) {
    aggregate {
      sum {
        amount
      }
    }
  }
  bets_draw2: bets_aggregate(where: $where2) {
    aggregate {
      sum {
        amount
      }
    }
  }
  bets_draw3: bets_aggregate(where: $where3) {
    aggregate {
      sum {
        amount
      }
    }
  }
}
`