import { Spin, Table } from "antd";
import React, { useMemo, useState } from "react";
import { useContext } from "react";
import { PageContext } from "../../../lib/context";
import { ExportCsvData } from "../exportCsvData";
import moment from "moment";

export const Bets = () => {
  const { bets, loading_bets, coordinatorId } = useContext(PageContext);

  const [tableData, setTableData] = useState([]);
  const [totals, setTotals] = useState({
    bets: 0,
    count: 0,
  });

  const columns = [
    {
      title: "Bet ID",
      dataIndex: "id",
    },
    {
      title: "Agent",
      dataIndex: "agent",
    },
    {
      title: "Agent Type",
      dataIndex: "agent_type",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Draw",
      dataIndex: "drawNumber",
    },
    {
      title: "Number",
      dataIndex: "number",
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Amount",
      dataIndex: "amount",
    },
  ];

  useMemo(() => {
    if (bets) {
      let total = 0;
      let count = 0;
      const data = [];
      bets.bets.map((bet) => {
        const item = {
          id: bet.id,
          agent: bet.user.username,
          agent_type: bet.user.agent_type,
          date: moment(bet.created_at).format("MMMM DD,YYYY HH:mm:ss"),
          number: bet.number,
          drawNumber: bet.drawNumber,
          type: bet.type,
          amount: bet.amount,
        };
        total = total + parseFloat(item.amount);
        count++;
        data.push(item);
      });
      setTableData(data);
      setTotals({
        bets: total,
        count,
      });
    } 
  }, [bets]);

  const bets_total_title = () => {
    return (
      <div className="text-xs font-bold">
        TOTAL SALES: {totals.bets.toFixed(2)}
        <br />
        TOTAL BET COUNT: {totals.count}
      </div>
    );
  };

  return (
    <div className="mt-10 flex flex-row">
      <div className="w-screen mr-4">
        <ExportCsvData
          title="bets"
          filename="Bets.csv"
          bets={tableData}
          totalSales={totals.bets}
          totalCount={totals.count}
        />
        <p className="text-xs font-bold mb-1">Bets</p>
        {loading_bets ? (
          <div className="flex h-full">
            <Spin className="self-center" />
          </div>
        ) : (
          <Table
            columns={columns}
            dataSource={tableData}
            title={bets_total_title}
          />
        )}
      </div>
    </div>
  );
};
