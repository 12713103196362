import React, { useState, useEffect } from "react";
import { PageContext } from "../../lib/context";
import { useSubscription } from "@apollo/react-hooks";
import _ from "lodash";
import { AGENTS, BETS } from "../../lib/subscriptions";
import BetsView from "./view";
import moment from "moment";
import jwt_decode from "jwt-decode";

const BetsController = () => {
  const [filters, setFilters] = useState({
    dateFrom: new Date(),
    dateTo: new Date(),
    draw: null,
    agent: null,
    transactionId: null,
    betId: null,
    combination: null,
    minPlay: null,
    maxPlay: null,
  });

  const [coordinatorId, setCoordinatorId] = useState();

  const { data: bets, loading: loading_bets } = useSubscription(BETS, {
    variables: {
      betsWhere: {
        _and: [
          {user: {coordinatorId: {_eq: coordinatorId}}},
          {
            created_at: {
              _gt: moment(filters.dateFrom).format("YYYY-MM-DD"),
            },
          },
          {
            created_at: {
              _lt: moment(filters.dateTo).add(1, "days").format("YYYY-MM-DD"),
            },
          },
          filters.minPlay !== null ? { amount: { _gte: filters.minPlay } } : {},
          filters.maxPlay !== null ? { amount: { _lte: filters.maxPlay } } : {},
          filters.transactionId !== null
            ? { transactionId: { _eq: filters.transactionId } }
            : {},
          filters.agent !== null
            ? { user: { id: { _in: filters.agent } } }
            : {},
          filters.draw !== null ? { drawNumber: { _eq: filters.draw } } : {},
          filters.betId !== null ? { id: { _eq: filters.betId } } : {},
          filters.combination !== null
            ? { number: { _eq: filters.combination } }
            : {},
        ],
      },
    },
  });

  const { data: agents, loading: loading_agents } = useSubscription(AGENTS,{
    variables:{
      coordinatorId: coordinatorId,
    }
  });

  useEffect(() => {
    let token = localStorage.getItem(process.env.REACT_APP_LS_TOKEN);
    var decode = jwt_decode(token);
    console.log("test",bets)
    console.log("test1",agents)
    console.log("test2",decode.sub)
    setCoordinatorId(decode.sub);
  },[bets,agents]);

  const handleSetFilter = ({ type, value }) => {
    switch (type) {
      case "date-from":
        if (!value) setFilters({ ...filters, dateFrom: new Date() });
        else setFilters({ ...filters, dateFrom: value });
        break;
      case "date-to":
        if (!value) setFilters({ ...filters, dateTo: new Date() });
        else setFilters({ ...filters, dateTo: value });
        break;
      case "draw":
        setFilters({ ...filters, draw: value });
        break;
      case "agent":
        setFilters({ ...filters, agent: value.length > 0 ? value : null });
        break;
      case "transactionID":
        setFilters({
          ...filters,
          transactionId: value.length > 0 ? value : null,
        });
        break;
      case "betId":
        setFilters({
          ...filters,
          betId: value.length > 0 ? value : null,
        });
        break;
      case "combination":
        setFilters({
          ...filters,
          combination: value.length > 0 ? value : null,
        });
        break;
      case "minPlay":
        setFilters({
          ...filters,
          minPlay: value.length > 0 ? value : null,
        });
        break;
      case "maxPlay":
        setFilters({
          ...filters,
          maxPlay: value.length > 0 ? value : null,
        });
      default:
        break;
    }
  };

  const pageValues = {
    bets,
    loading_bets,
    filters,
    setFilters,
    handleSetFilter,
    agents,
    loading_agents,
  };

  return (
    <PageContext.Provider value={pageValues}>
      <BetsView />
    </PageContext.Provider>
  );
};

export default BetsController;
