import { useQuery } from "@apollo/react-hooks";
import React, { useMemo, useState,useEffect } from "react";
import { PageContext } from "../../lib/context";
import { OPSTAT_BETS } from "../../lib/query";
import OperationStatsView from "./view.jsx";
import moment from "moment";
import _ from "lodash";
import jwt_decode from "jwt-decode";

const OperationStatsController = () => {
  const [date, setDate] = useState(new Date());
  const [coordinatorId, setCoordinatorId] = useState();
  const [dailyData, setDailyData] = useState({
    totalBetsAmount: 0,
    totalBetsCount: 0,
    tableData: [],
  });
  const [draw1, setDraw1] = useState({
    totalBetsAmount: 0,
    totalBetsCount: 0,
    tableData: [],
  });
  const [draw2, setDraw2] = useState({
    totalBetsAmount: 0,
    totalBetsCount: 0,
    tableData: [],
  });
  const [draw3, setDraw3] = useState({
    totalBetsAmount: 0,
    totalBetsCount: 0,
    tableData: [],
  });

  const [betsWhere, setBetsWhere] = useState({
    _and: [
      {user: {coordinatorId: {_eq: coordinatorId}}},
      { created_at: { _gt: moment(date).format("YYYY-MM-DD") } },
      { created_at: { _lt: moment(date).add(1, "days").format("YYYY-MM-DD") } },
    ],
  });
  const { data: bets, loading: loading_bets } = useQuery(OPSTAT_BETS, {
    variables: {
      betsWhere,
    },
  });

  useEffect(() => {
    let token = localStorage.getItem(process.env.REACT_APP_LS_TOKEN);
    var decode = jwt_decode(token);
    console.log("test",bets)
    setCoordinatorId(decode.sub);
  },[bets]);

  useMemo(() => {
    if (bets) {
      function getNumbers(betsArr, totalBetsAmount, totalBetsCount, cb) {
        let numbers = _.chain(betsArr)
          .groupBy("number")
          .map((value, key) => ({ number: key, bets: value }))
          .value();

        numbers = _.orderBy(numbers, (o) => o.bets.length, ["desc"]);
        const tableData = [];

        numbers.map((number) => {
          const data = {
            number: number.number,
            betCount: number.bets.length,
            amount: _.sumBy(number.bets, (o) => o.amount),
            percentage: (
              (_.sumBy(number.bets, (o) => o.amount) * 100) /
              totalDailyBetsAmount
            ).toFixed(3),
          };
          tableData.push(data);
        });
        cb({
          totalBetsAmount,
          totalBetsCount,
          tableData,
        });
      }

      // daily data
      const totalDailyBetsAmount = _.sumBy(bets.bets, (o) => o.amount);
      const totalDailyBetsCount = bets.bets.length;
      getNumbers(
        bets.bets,
        totalDailyBetsAmount,
        totalDailyBetsCount,
        setDailyData
      );

      // draw1 data
      let draws = _.chain(bets.bets)
        .groupBy("drawNumber")
        .map((value, key) => ({ drawNumber: key, bets: value }))
        .value();

      const draw1 = _.filter(draws, (o) => o.drawNumber === "1");
      const draw1TotalAmount = _.sumBy(draw1[0]?.bets, (o) => o?.amount) ?? 0;
      const draw1TotalBetsCount = draw1[0]?.bets?.length ?? 0;
      getNumbers(
        draw1[0]?.bets ?? [],
        draw1TotalAmount,
        draw1TotalBetsCount,
        setDraw1
      );

      const draw2 = _.filter(draws, (o) => o.drawNumber === "2");
      const draw2TotalAmount = _.sumBy(draw2[0]?.bets, (o) => o?.amount) ?? 0;
      const draw2TotalBetsCount = draw2[0]?.bets?.length ?? 0;
      getNumbers(
        draw2[0]?.bets ?? [],
        draw2TotalAmount,
        draw2TotalBetsCount,
        setDraw2
      );

      const draw3 = _.filter(draws, (o) => o.drawNumber === "3");
      const draw3TotalAmount = _.sumBy(draw3[0]?.bets, (o) => o?.amount) ?? 0;
      const draw3TotalBetsCount = draw3[0]?.bets?.length ?? 0;
      getNumbers(
        draw3[0]?.bets,
        draw3TotalAmount,
        draw3TotalBetsCount,
        setDraw3
      );
    }
  }, [bets]);

  const handleDateChange = (date) => {
    setBetsWhere({
      _and: [
        { created_at: { _gt: moment(date).format("YYYY-MM-DD") } },
        {
          created_at: { _lt: moment(date).add(1, "days").format("YYYY-MM-DD") },
        },
      ],
    });
  };

  const values = {
    bets,
    loading_bets,
    date,
    setDate,
    dailyData,
    draw1,
    draw2,
    draw3,
    handleDateChange,
  };

  return (
    <PageContext.Provider value={values}>
      <OperationStatsView />
    </PageContext.Provider>
  );
};

export default OperationStatsController;
