import React, { useEffect } from "react";
import { useContext, useState } from "react";
import { PageContext } from "../../../lib/context";
import { Spin, Table } from "antd";
import {
  formatMoney,
  calculateWinnersAndWinnings,
  getTotalWinnersAndWinnings,
} from "../../../lib/util";
import { ExportCsvData } from "../exportCsvData";
import { useQuery } from "@apollo/react-hooks";
import moment from "moment";
import { GET_AGENT_BETS } from "../../../lib/query";
import _ from "lodash";
import { USERS } from "../../../lib/subscriptions";

export const Weekly = () => {
  const { getColumnSearchProps, filters, coordinatorId } = useContext(PageContext);
  const [totals, setTotals] = useState({
    bets: 0,
    betCount: 0,
    winners: 0,
    winnings: 0,
  });

  const [tableData, setTableData] = useState([]);

  const { data: weekly_bets, loading: loading_weekly_bets } = useQuery(
    GET_AGENT_BETS,
    {
      variables: {
        resultsWhere: {
          _and: [
            { date: { _gt: moment(filters.dateFrom).format("YYYY-MM-DD") } },
            {
              date: {
                _lt: moment(filters.dateTo).add(1, "days").format("YYYY-MM-DD"),
              },
            },
            filters?.draw !== null ? { drawNumber: { _eq: filters.draw } } : {},
          ],
        },
        betsWhere: {
          _and: [
            {
              created_at: {
                _gt: moment(filters.dateFrom).format("YYYY-MM-DD"),
              },
            },
            {
              created_at: {
                _lt: moment(filters.dateTo).add(1, "days").format("YYYY-MM-DD"),
              },
            },
            filters?.draw !== null ? { drawNumber: { _eq: filters.draw } } : {},
          ],
          user:
            filters?.agent?.length > 0 ? { id: { _in: filters.agent } } : {},
        },
        usersWhere: {
          _and: [
            { type: { _eq: "agent" },coordinatorId: { _eq: coordinatorId } },
            filters?.agent?.length > 0 ? { id: { _in: filters.agent } } : {},
            filters?.agent_type !== null ? { agent_type: { _eq: filters.agent_type } } : {},
          ],
        },
      },
    }
  );

  const handleData = (start, end, payload) => {
    let total = 0;
    let count = 0;
    let winners = 0;
    let winnings = 0;
    let remittance = 0;

    weekly_bets.users.map((user) => {
      const type = user.agent_type;
      const results = _.filter(weekly_bets?.results, (o) =>
        moment(moment(o.date).format("YYYY-MM-DD")).isBetween(
          moment(start).subtract(1, "days"),
          moment(end).add(1, "days")
        )
      );
      const bets = _.filter(user.bets, (o) =>
        moment(moment(o.created_at).format("YYYY-MM-DD")).isBetween(
          moment(start).subtract(1, "days"),
          moment(end).add(1, "days")
        )
      );
      const calcWinners = calculateWinnersAndWinnings(
        weekly_bets?.serverConfigs,
        results,
        bets,
        type
      ).totalWinners;
      const calcWinnings = calculateWinnersAndWinnings(
        weekly_bets?.serverConfigs,
        results,
        bets,
        type
      ).totalWinnings;

      const data = {
        date: `${start} - ${end}`,
        username: user.username,
        agent_type: user.agent_type,
        sales: calculateWinnersAndWinnings(
          weekly_bets?.serverConfigs,
          results,
          bets,
          type
        ).totalSales,
        winners: calcWinners,
        winnings: calcWinnings,
        remittance:
          calculateWinnersAndWinnings(
            weekly_bets?.serverConfigs,
            results,
            bets,
            type
          ).totalWinnings > 0
            ? calculateWinnersAndWinnings(
                weekly_bets?.serverConfigs,
                results,
                bets,
                type
              ).totalSales -
              calculateWinnersAndWinnings(
                weekly_bets?.serverConfigs,
                results,
                bets,
                type
              ).totalWinnings
            : 0,
      };
      total = total + parseFloat(data.sales);
      winners = winners + calcWinners;
      winnings = winnings + parseFloat(calcWinnings);
      remittance = remittance + data.remittance;
      count = count + bets.length;
      payload.push(data);
    });
    return { total, winnings, winners, count, remittance };
  };

  useEffect(() => {
    if (weekly_bets) {
      const payload = [];
      let total = 0;
      let count = 0;
      let winners = 0;
      let winnings = 0;
      let remittance = 0;

      const from = moment(filters.dateFrom).format("YYYY-MM-DD");
      const to = moment(filters.dateTo).format("YYYY-MM-DD");
      const days = moment(to).diff(from, "days");

      let start = from;
      let end = to;

      const numberOfWeeks = days / 7;
      if (numberOfWeeks >= 1) {
        for (var i = 1; i <= numberOfWeeks; i++) {
          end = moment(start).add(1, "weeks").format("YYYY-MM-DD");
          if (i !== 1)
            start = moment(start).add(1, "days").format("YYYY-MM-DD");

          const {
            total: t,
            count: c,
            winners: w,
            winnings: ws,
            remittance: rm,
          } = handleData(start, end, payload);

          start = end;

          total = total + t;
          winners = winners + w;
          winnings = winnings + ws;
          remittance = remittance + rm;
          count = count + c;

          if (i === Math.floor(numberOfWeeks)) {
            setTableData(payload);
            setTotals({
              bets: total,
              betCount: count,
              winnings,
              winners,
              remittance,
            });
          }
        }
      }

      const extraDays = days % 7;
      if (extraDays) {
        start = moment(end).add(1, "days").format("YYYY-MM-DD");
        end = moment(start)
          .add(extraDays - 1, "days")
          .format("YYYY-MM-DD");
        const {
          total: t,
          count: c,
          winners: w,
          winnings: ws,
          remittance: rm,
        } = handleData(start, end, payload);

        total = total + t;
        winners = winners + w;
        winnings = winnings + ws;
        remittance = remittance + rm;
        count = count + c;

        setTableData(payload);
        setTotals({
          bets: total,
          betCount: count,
          winnings,
          winners,
          remittance,
        });
      }

      if (from === to) {
        const {
          total: t,
          count: c,
          winners: w,
          winnings: ws,
          remittance: rm,
        } = handleData(from, to, payload);

        total = total + t;
        winners = winners + w;
        winnings = winnings + ws;
        remittance = remittance + rm;
        count = count + c;

        setTableData(payload);
        setTotals({
          bets: total,
          betCount: count,
          winnings,
          winners,
          remittance,
        });
      }
    }
  }, [weekly_bets, filters]);

  const weekly_sales = [
    {
      title: "Date",
      key: "date",
      dataIndex: "date",
      ...getColumnSearchProps("date"),
    },
    {
      title: "Agent",
      key: "agent",
      dataIndex: "username",
      ...getColumnSearchProps("username"),
    },
    {
      title: "Agent Type",
      key: "agent_type",
      dataIndex: "agent_type",
      ...getColumnSearchProps("agent_type"),
    },
    {
      title: "Sales",
      key: "sales",
      dataIndex: "sales",
      sorter: {
        compare: (a, b) => {
          return a.sales - b.sales;
        },
        multiple: 3,
      },
    },
    {
      title: "Winners",
      key: "winners",
      dataIndex: "winners",
      sorter: {
        compare: (a, b) => {
          return a.winners - b.winners;
        },
        multiple: 3,
      },
    },
    {
      title: "Winnings",
      key: "winnings",
      dataIndex: "winnings",
      sorter: {
        compare: (a, b) => {
          return a.winnings - b.winnings;
        },
        multiple: 3,
      },
    },
    {
      title: "Remittance",
      key: "remittance",
      dataIndex: "remittance",
      sorter: {
        compare: (a, b) => {
          return a.remittance - b.remittance;
        },
        multiple: 3,
      },
    },
  ];

  const weekly_sales_total_title = () => {
    return (
      <div className="text-xs font-bold">
        TOTAL SALES: {totals.bets.toFixed(2)}
        <br />
        TOTAL BET COUNT: {totals.betCount}
      </div>
    );
  };

  return (
    <>
      <div className="mt-10 flex flex-row">
        <div className="w-screen mr-4">
          <ExportCsvData
            title="weekly"
            filename="Weekly.csv"
            totalSales={totals.bets}
            totalWinnings={totals.winnings}
            totalWinners={totals.winners}
            totalRemittance={totals.remittance}
            data={tableData}
          />
          <p className="text-xs font-bold mb-1">Weekly Sales</p>
          {loading_weekly_bets ? (
            <div className="flex h-full">
              <Spin className="self-center" />
            </div>
          ) : (
            <Table
              dataSource={tableData}
              columns={weekly_sales}
              title={weekly_sales_total_title}
            />
          )}
        </div>
      </div>
    </>
  );
};
