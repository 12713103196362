import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { authGuard, AuthGuard, DashGuard } from "./modules";
import { ApolloProvider } from "@apollo/react-hooks";
import { AppContext } from "../lib/context";
import makeApolloClient from "../lib/apollo";
import login from "../pages/login";
import DashboardController from "../pages/dashboard";
import AgentsController from "../pages/agents";
import LimitsController from "../pages/limits";
import ResultsController from "../pages/results";
import BetsController from "../pages/bets";
import ServerConfigurationController from "../pages/server-configuration";
import ReportsController from "../pages/reports";
import UsersInfoController from "../pages/user-info";
import OperationStatsContoller from "../pages/operation-stats";
import TopUpDashboardController from "../pages/topup-dashboard";

export default function App() {
  const [client, setClient] = useState(null);

  const { TOKEN } = useContext(AppContext);

  const initClient = async () => {
    let client;
    const token = localStorage.getItem(process.env.REACT_APP_LS_TOKEN);
    if (token) {
      client = makeApolloClient(token);
    } else {
      client = makeApolloClient(process.env.REACT_APP_DEFAULT_JWT);
    }
    setClient(client);
  };

  useEffect(() => {
    initClient();
  }, []);

  if (!client) return null;

  return (
    <Router>
      <ApolloProvider client={client}>
        <Switch>
          <AuthGuard exact path="/" component={login} />
          <DashGuard exact path="/dashboard" component={DashboardController} />
          <DashGuard exact path="/topup-dashboard" component={TopUpDashboardController} />
          <DashGuard exact path="/agents" component={AgentsController} />
          {/* <DashGuard exact path="/limits" component={LimitsController} /> */}
          <DashGuard exact path="/results" component={ResultsController} />
          <DashGuard exact path="/bets" component={BetsController} />
          <DashGuard exact path="/reports" component={ReportsController} />
          <DashGuard
            exact
            path="/operation-stats"
            component={OperationStatsContoller}
          />
          <DashGuard exact path="/user-info" component={UsersInfoController} />
          {/* <DashGuard
            exact
            path="/server-configuration"
            component={ServerConfigurationController}
          /> */}
        </Switch>
      </ApolloProvider>
    </Router>
  );
}
