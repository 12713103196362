import React, { useEffect, useMemo, useState } from "react";
import DashboardView from "./view";
import { PageContext } from "../../lib/context";
// import {
//   GET_DAILY_BETS,
//   GET_WEEKLY_BETS,
//   GET_MONTHLY_BETS,
// } from "../../lib/query";
import { useQuery, useSubscription } from "@apollo/react-hooks";
import moment from "moment";
import _ from "lodash";
import {
  BETS,
  GET_DAILY_BETS,
  GET_WEEKLY_BETS,
  GET_MONTHLY_BETS,
  GET_ACTIVE_AGENTS,
  GET_AGENT_TODAY,
} from "../../lib/subscriptions";
import jwt_decode from "jwt-decode";

const DashboardController = () => {
  const [coordinatorId, setCoordinatorId] = useState();

  const { data: bets, loading: loading_bets } = useSubscription(BETS,{
    variables: {
     betsWhere: {
       _and: [
         {user: {coordinatorId: {_eq: coordinatorId}}}],
     }
     }
   });
  const {
    data: daily_bets,
    loading: loading_daily,
    error,
  } = useSubscription(GET_DAILY_BETS, {
    variables: {
      date: moment().format("YYYY-MM-DD"),
      coordinatorId: coordinatorId,
    },
    //   variables: {
    //     betsWhere:{
    //       _and: [
    //         {
    //       date: moment().format("YYYY-MM-DD")
    //       },
    //       ],
    //   },
    // }
  });



  const { data: weekly_bets, loading: loading_weekly } = useSubscription(
    GET_WEEKLY_BETS,
    {
      variables: {
        date: moment().subtract(7, "days").format("YYYY-MM-DD"),
        coordinatorId: coordinatorId,
      },
    }
  );

  const { data: monthly_bets, loading: loading_monthly } = useSubscription(
    GET_MONTHLY_BETS,
    {
      variables: {
        date: moment().subtract(1, "months").format("YYYY-MM-DD"),
        coordinatorId: coordinatorId,
      },
    }
  );

  const { data:agents_today } = useSubscription(GET_AGENT_TODAY,{
    variables:{
      date: moment().format("YYYY-MM-DD"),
      coordinatorId: coordinatorId,
    }
  });


  

  // const { data: bets, loading: loading_bets } = useSubscription(BETS, {
  //   variables: {
  //     betsWhere: {
  //       _and: [
  //         {user: {coordinatorId: {_eq: coordinatorId}}},
  //       ],
  //     },
  //   },
  // });

  const {data : active_agents, loading: loading_agents} = useSubscription(GET_ACTIVE_AGENTS,{
    variables:{
      coordinatorId: coordinatorId,
    }
  })


  useEffect(() => {
    let token = localStorage.getItem(process.env.REACT_APP_LS_TOKEN);
    var decode = jwt_decode(token);
    setCoordinatorId(decode.sub);
  },[bets]);

  useEffect(() => {
    console.log("daily_bets",error)
  },[daily_bets,error]);

  const pageValues = {
    daily_bets,
    loading_daily,
    weekly_bets,
    loading_weekly,
    monthly_bets,
    loading_monthly,
    bets,
    loading_bets,
    active_agents,
    loading_agents,
    agents_today,
  };

  return (
    <PageContext.Provider value={pageValues}>
      <DashboardView />
    </PageContext.Provider>
  );
};

export default DashboardController;
