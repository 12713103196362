import React, { useState, useRef } from "react";
import { useContext } from "react";
import { PageContext } from "../../lib/context";
import { Button, Popconfirm, Spin, Table, Input, Space, Tabs } from "antd";
import { bets_columns, individual_columns } from "./tables";
import { IndividualModal } from "./components/individual";
import moment from "moment";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import { GlobalModal } from "./components/global";

const { TabPane } = Tabs;

const LimitsView = () => {
  const {
    setVisible,
    loading_individual,
    individual_limits,
    visible,
    bets,
    confirm,
    global_limits,
    loading_global,
    getRemainingLimit,
    loading_bets,
  } = useContext(PageContext);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const individual_columns = [
    {
      title: "Number",
      dataIndex: "number",
      key: "number",
      ...getColumnSearchProps("number"),
    },
    {
      title: "Current limit",
      dataIndex: "currentLimit",
      key: "currentLimit",
      ...getColumnSearchProps("currentLimit"),
    },
    {
      title: "Draw",
      dataIndex: "draw",
      key: "draw",
      ...getColumnSearchProps("draw"),
    },
    {
      title: "Date",
      render: (item) => {
        if (!item.date) {
          return <p>Everyday</p>;
        } else {
          return <p>{moment(item.date).format("MMMM DD, YYYY")}</p>;
        }
      },
    },
    {
      title: "Remaining",
      render: (item) => (
        <p>
          {getRemainingLimit(
            item.number,
            item.date,
            item.currentLimit,
            item.draw
          )}
        </p>
      ),
    },
    // {
    //   title: "Actions",
    //   render: (item) => {
    //     return (
    //       <Popconfirm
    //         title={`Delete ${item.number}?`}
    //         onConfirm={() => confirm({ ...item })}
    //         cancelButtonProps={{ style: { display: "none" } }}
    //         okText="Yes"
    //         cancelText="No"
    //       >
    //         <DeleteOutlined className="cursor-pointer" />
    //       </Popconfirm>
    //     );
    //   },
    // },
  ];

  const global_columns = [
    {
      title: "Number",
      dataIndex: "number",
      key: "number",
      ...getColumnSearchProps("number"),
    },
    {
      title: "Current limit",
      dataIndex: "currentLimit",
      key: "currentLimit",
      ...getColumnSearchProps("currentLimit"),
    },
    {
      title: "Remaining",
      render: (item) => (
        <p>{getRemainingLimit(item.number, null, item.currentLimit)}</p>
      ),
      sorter: {
        compare: (a, b) => {
          return (
            getRemainingLimit(a.number, null, a.currentLimit) -
            getRemainingLimit(b.number, null, b.currentLimit)
          );
        },
        multiple: 3,
      },
    },
  ];

  return (
    <div className="flex flex-col h-auto pt-20 px-20">
      <p className="text-3xl font-bold secondary-color">Limits</p>
      <Tabs defaultActiveKey="1" size="large" type="card">
        <TabPane tab="Individual" key="1">
          <div className="mt-10 flex flex-row">
            {loading_individual && loading_bets && <Spin />}
            <div className="w-screen mr-4">
              {/* <Button
                className="w-32 mb-5"
                type="primary"
                onClick={() => {
                  setVisible({ ...visible, limit: true });
                }}
              >
                Add limit
              </Button> */}
              <p className="text-xs font-bold">Individual Limits</p>
              {loading_individual ? (
                <div className="flex h-full">
                  <Spin className="self-center" />
                </div>
              ) : (
                <Table
                  dataSource={individual_limits?.betsLimits ?? []}
                  columns={individual_columns}
                />
              )}
            </div>
          </div>
        </TabPane>
        <TabPane tab="Global" key="2">
          <div className="mt-10 flex flex-row">
            {loading_global && loading_bets && <Spin />}
            <div className="w-screen mr-4">
              {/* <Button
                onClick={() => {
                  setVisible({ ...visible, global: true });
                }}
                className="w-20 mb-5"
                type="primary"
              >
                Set Global limit
              </Button> */}
              <p className="text-xs font-bold">Global Limits</p>
              {loading_global ? (
                <div className="flex h-full">
                  <Spin className="self-center" />
                </div>
              ) : (
                <Table
                  dataSource={global_limits?.betsLimits ?? []}
                  columns={global_columns}
                />
              )}
            </div>
          </div>
        </TabPane>
      </Tabs>

      <IndividualModal />
      <GlobalModal />
    </div>
  );
};

export default LimitsView;
